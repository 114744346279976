import React from 'react'

import Container from '../Container'
import './Footer.scss'

class Footer extends React.Component {
  render() {
    return (
      <footer className="site-footer">
        <Container>
          <div className="footer-hero footer-col">
            <div className="footer-branding">
              <a className="footer-title" href="/">
                {this.props.siteMeta.name}
              </a>
            </div>
            <span className="footer-copyright">
              Copyright &copy; 2018 {this.props.siteMeta.name}
            </span>
            <a className="footer-privacy" href="/privacy-policy/">
              Privacy Policy
            </a>
          </div>
          <div className="footer-col footer-links">
            <ul className="footer-list">
              <li className="footer-item">
                <a href="/">Home</a>
              </li>
              <li className="footer-item">
                <a href="/blog/">Blog</a>
              </li>
              <li className="footer-item">
                <a href="/download-printable-pdf">Download</a>
              </li>
              <li className="footer-item">
                <a href="/about/">About</a>
              </li>
              <li className="footer-item">
                <a href="/about/#how-can-i-contact-you">Contact</a>
              </li>
            </ul>
          </div>
        </Container>
        <Container>
          <div className="tool-links">
            <ul className="footer-list">
              <li className="footer-item">
                <a href="https://www.base64encoder.io">Base64 Encoder</a>
              </li>
              <li className="footer-item">
                <a href="https://www.base64decoder.io">Base64 Decoder</a>
              </li>
              <li className="footer-item">
                <a href="https://www.jsonformatter.io">JSON Formatter</a>
              </li>
              <li className="footer-item">
                <a href="https://www.urlencoder.io">URL Encoder</a>
              </li>
              <li className="footer-item">
                <a href="https://www.urldecoder.io">URL Decoder</a>
              </li>
              <li className="footer-item">
                <a href="https://www.emicalculator.loan">EMI Calculator</a>
              </li>
              <li className="footer-item">
                <a href="https://www.callicoder.com">CalliCoder</a>
              </li>
            </ul>
          </div>
        </Container>
        <Container>
          <div className="tool-links">
            <ul className="footer-list">
              <li className="footer-item">
                <a href="/ascii-text-to-binary-converter">ASCII to Binary</a>
              </li>
              <li className="footer-item">
                <a href="/ascii-text-to-decimal-converter">ASCII to Decimal</a>
              </li>
              <li className="footer-item">
                <a href="/ascii-text-to-hex-converter">ASCII to Hex</a>
              </li>
            </ul>
          </div>
        </Container>
      </footer>
    )
  }
}

export default Footer
