import React from 'react'

import './Header.scss'
import Container from '../Container'

class Header extends React.Component {
  render() {
    return (
      <header className="site-header shadowed">
        <Container>
          <div className="site-branding">
            <a href={'/'} className="site-title">
              {this.props.siteMeta.name}
            </a>
          </div>
          <nav className="site-nav">
            <ul className="nav-list">
              <li className="nav-item">
                <a href="/extended/">Extended</a>
              </li>
              <li className="nav-item">
                <a href="/blog/">Blog</a>
              </li>
            </ul>
          </nav>
        </Container>
      </header>
    )
  }
}

export default Header
